<template>
  <div class="church-presentation-template">
    <page-header>
      <h1 class="d-none">
        <span v-if="!userSelectedEmail.eml_key">{{ translations.tcAdd }}</span>
        <span v-if="!!userSelectedEmail.eml_key">{{ translations.tcEdit }}</span> {{ translations.tcEmail }}
      </h1>
    </page-header>

    <page-body class>
      <email-form v-if="translations.components.common" :churchName="orgName" :churchKey="userSelectedFacilityKey"
        :locationName="orgName" :locationKey="userSelectedFacilityKey" :userKey="userIndKey" :email="userSelectedEmail"
        :i18n="translations.components"></email-form>
    </page-body>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import emailForm from '@/components/EmailForm.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'card_facility_edit_email',
  mixins: [translationMixin],
  data() {
    return {
      translations: {
        components: {
          'email-form': {},
        },
      },
      orgName: null,
    }
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      this.orgName = this.location.org_name
      await Promise.all([
        this.getViewTranslations('buttons', 'forms'),
        this.getComponentTranslations('email-form'),
        this.getComponentTranslations('common.email-types'),
      ]).then(results => {
        this.stripReadableText(results[2])
        const translatedText = {
          ...results[1],
          common: { ...this.translations.common }
        }
        this.$set(this.translations, 'components', translatedText)
      })
    } catch (e) {
      console.error('Error in created(), EditGideonCardEmail.vue', e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  computed: {
    ...mapGetters({
      location: 'card/getFacilitySummary',
      prefCulture: 'user/userPreferredCulture',
      userIndKey: 'user/userId',
      userSelectedFacilityKey: 'user/userSelectedFacilityKey',
      userSelectedEmail: 'user/userSelectedEmail'
    }),
  },
  components: {
    emailForm: emailForm,
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader
  },
}
</script>

<style scoped>
.page-body {
  padding: 80px 15px;
}
</style>
